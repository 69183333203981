import { AxiosResponse } from "axios";
import { flow, makeObservable } from "mobx";
import { IDispatchItem } from "./Interface/IDispatchTabStore";
import { IBoard } from "ms_data";
import { ServerStore } from "ms-ui";
import { get_dispatch_list_by_board_id } from "src/repository/Board/dispatch";

export class DispatchTabStore extends ServerStore<IDispatchItem[]> {
  constructor() {
    super();
    makeObservable(this, {
      loadData: flow.bound,
    });
  }

  *loadData(boardId: string) {
    try {
      this.setLoading(true);
      const res: AxiosResponse<{
        board: IBoard;
        dispatchList: IDispatchItem[];
      }> = yield get_dispatch_list_by_board_id({ boardId }).req();

      this.setData(res.data.dispatchList);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }
}
