import { observer } from "mobx-react";
import { IDrInfo } from "../Form";
import { DrInfo } from "./DrInfo";
import { isNil } from "ms_data";
import { ms_file } from "src/constants/addr";
import styled from "styled-components";
import { Caption1, CenterFlex, contents } from "ms-ui";

export const DrInfoTpl = observer((props: IDrInfo) => {
  const { dispatchStore, modalStore } = props;
  const { drImg, dr, dispatch } = dispatchStore.data!;
  const width = window.innerWidth > 420 ? 420 : window.innerWidth;

  return (
    <DrInfo
      nm={dr.nm}
      phone={dr.phone}
      period={`${dispatch?.startDay} ~ ${dispatch?.endDay || ""}`}
      img={
        !isNil(drImg) === true ? (
          <img src={`${ms_file}/${drImg?.img}`} width={`100%`} />
        ) : (
          <StBlankBox width={width - 32}>
            이미지가 없습니다.
            <br />
            빠른 시일내에 올려드릴게요!
          </StBlankBox>
        )
      }
      visible={dispatch?.dispatchCd === "b"}
      msg={
        <>
          {dispatch.startDay} 부터 임시로
          <br />
          운행을 맡아주실 기사님 입니다.
        </>
      }
    />
  );
});

export const StBlankBox = styled.div<{ width: number }>`
  ${CenterFlex}
  ${Caption1}
  width: ${(p) => p.width}px;
  height: calc(${(p) => p.width}px / 5 * 3);
  background-color: ${contents.background.value};
  border-radius: ${contents.tertiary.value};
  color: ${contents.description.value};
`;
