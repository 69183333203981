/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetRefund  } from './interface/res/get/Res.Get.Refund';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Refund {

get_refund_list(data: {   orderNo: string }){
 return GET<ResGetRefund>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/refund`,
 }, data);
}
 
}

export const RefundRepository = new Refund();
export const { get_refund_list } = RefundRepository;
	