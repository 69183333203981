import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { DispatchTabStore } from "../Store/DispatchTabStore";
import { isNil } from "ms_data";
import { ModalStore, spacing } from "ms-ui";
import { ModalLoader } from "ms-ui";
import styled from "styled-components";
import { useURI } from "ms_router";
import { DrDispatchTpl } from "./Comp/DrDispatchTpl";
import { DrInfo } from "./Comp/DrInfo";
import { TabStore } from "../Store/TabStore";

export interface IDispatchTab {
  dispatchTabStore: DispatchTabStore;
  modalStore?: ModalStore;
  tabStore: TabStore;
}

/**
 * 배차 정보
 */
export const DispatchTab = observer((props: IDispatchTab) => {
  const [modalStore] = React.useState(new ModalStore());
  const { dispatchTabStore, tabStore } = props;
  const { loadData, isLoading, data } = dispatchTabStore;
  const q = useURI().getQuery<{ boardId: string }>();

  useEffect(() => {
    if (isNil(dispatchTabStore.data) && !isNil(q.boardId)) {
      if (tabStore.list.find((tab) => tab.key === "dispatch") !== undefined) {
        loadData(q.boardId);
      }
    }
  }, [q.boardId]);

  return (
    <>
      <ModalLoader active={isLoading} />

      <StList>
        {!isNil(data) && isLoading !== true ? (
          <>
            <DrDispatchTpl {...props} modalStore={modalStore} />

            {!isNil(modalStore.select) && (
              <DrInfo {...props} modalStore={modalStore} />
            )}
          </>
        ) : null}
      </StList>
    </>
  );
});

export const StList = styled.div`
  height: calc(100dvh - 100px);
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};

  & > :first-child {
    margin-top: 4px;
  }
`;
