import { useEffect } from "react";
import { observer } from "mobx-react";
import { PaidTabStore } from "../Store/PaidTabStore";
import { PaidList } from "./Comp/PaidList";
import { isNil } from "ms_data";
import { ModalStore } from "ms-ui";
import { useURI } from "ms_router";
import { ModalLoader } from "ms-ui";

export interface IPaidTab {
  paidTabStore: PaidTabStore;
  modalStore?: ModalStore;
}

// 결제내역 Tab
export const PaidTab = observer((props: IPaidTab) => {
  const { paidTabStore } = props;
  const { data, isLoading, loadData } = paidTabStore;
  const q = useURI().getQuery<{ boardId: string }>();

  useEffect(() => {
    if (isNil(paidTabStore.data) && !isNil(q.boardId)) {
      loadData(q.boardId);
    }
  }, [q.boardId]);

  return (
    <>
      <ModalLoader active={isLoading} />

      {!isNil(data) && isLoading !== true ? (
        <PaidList {...props} paidTabStore={paidTabStore} />
      ) : null}
    </>
  );
});
