import React from "react";
import { observer } from "mobx-react";
import { spacing, foundation, contents, Body2, Button } from "ms-ui";
import styled from "styled-components";

export interface IPayRequestDefault {
  day: string;
  dayVisible: boolean;
  onClickHandler: () => void;
}

export const PayRequestDefault = observer(
  ({ day, dayVisible, onClickHandler }: IPayRequestDefault) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            <StDiv3>아직 결제가 되어있지 않습니다.</StDiv3>
            {dayVisible && (
              <StDiv4>
                <StDiv5>결제는</StDiv5>
                <StDiv6>{day}</StDiv6>
                <StDiv7>16:00:00 까지 가능합니다.</StDiv7>
              </StDiv4>
            )}
          </StDiv2>
          <Button
            color={"black"}
            size={"sm"}
            radius={"5"}
            outline={true}
            onClick={onClickHandler}
            width={"100%"}
          >
            결제하러 가기
          </Button>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  background: ${foundation.light.primary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Body2};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv4 = styled.div`
  display: flex;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;
