import styled from "styled-components";
import {
  Body2,
  Caption1,
  CenterFlex,
  colors,
  contents,
  spacing,
  Subtitle2,
  Subtitle3,
} from "ms-ui";

export const ParagraphTitle = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${spacing[8].value};

  & > span {
    color: ${contents.primary.value};
    ${Subtitle2}
  }
  margin-bottom: ${spacing[24].value};
`;

export const ParagraphSubTitle = styled.p`
  ${Caption1}
  color: ${colors.mainblue[400].value};
  margin-top: 6px;
`;

// emptyList
export const EmptyList = styled.div`
  background-color: ${contents.background.value};
  ${Body2}
  color: ${contents.accent.value};
  ${CenterFlex}
  padding: ${spacing[24].value} ${spacing[16].value};
  text-align: center;
  height: 100%;
  margin: auto;
`;

// table
export const TableTitle = styled.div`
  ${Subtitle2}
  color: ${contents.primary.value};
  margin-bottom: ${spacing[24].value};
`;

export const TableKey = styled.div`
  ${Body2}
  color: ${contents.accent.value};
`;

export const TableValue = styled.div`
  ${Body2}
  width: 100%;
  color: ${contents.primary.value};
`;

export const Table = styled.table<{
  direction?: "right";
  size?: "bold";
  $thWidth?: string;
}>`
  ${Body2}
  width: 100%;
  margin-bottom: ${spacing[12].value};

  tbody {
    display: flex;
    flex-direction: column;
    gap: ${spacing[12].value};

    tr {
      display: flex;
      ${(p) => p.direction === "right" && "justify-content: space-between"};

      th {
        color: ${contents.accent.value};
        text-align: left;
        ${(p) =>
          p.$thWidth !== undefined ? `width: ${p.$thWidth}` : "width: 112px"}
      }

      td {
        ${(p) => p.size === "bold" && `${Subtitle3}`}
        color: ${contents.primary.value};
        width: ${(p) =>
          p.$thWidth !== undefined
            ? `calc(100% - ${p.$thWidth})`
            : `calc(100% - 112px)`};
      }
    }
  }
`;
