import { IApply, IBoard, IPaid, IPay, IPayTry, IRt, isNil } from "ms_data";

interface IPayReqLogic {
  pay: IPay;
  payTry?: IPayTry;
  paid: IPaid;
  apply: IApply;
  board: IBoard;
  rt: IRt;
}

export class PayReqLogic {
  _data: IPayReqLogic;

  constructor(obj: IPayReqLogic) {
    this._data = obj;
  }

  /**
   * 결제 요청 노출 여부
   *
   * pay는 있지만(1) paid는 없고(2), 무료 탑승이 아니고(3), 탑승신청 or 결제대기일 때(4)
   */
  get visible(): boolean {
    const { pay, paid, apply, board, rt } = this._data;

    return (
      // 1
      !isNil(pay) &&
      // 2
      isNil(paid) &&
      // 3
      apply.prodsCd !== 0 &&
      // 4
      (board.boardCd === "a" || board.boardCd === "d") &&
      rt?.rtStatusCd !== "a"
    );
  }
}
