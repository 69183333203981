import { observer } from "mobx-react";
import { DrDispatch } from "./DrDispatch";
import { IDispatchTab } from "../..";
import { IDispatchItem } from "../../../Store/Interface/IDispatchTabStore";
import { IDispatch, isNil, mFormat } from "ms_data";
import styled from "styled-components";
import {
  Body2,
  CenterFlex,
  Subtitle3,
  contents,
  foundation,
  spacing,
} from "ms-ui";

export const DrDispatchTpl = observer((props: IDispatchTab) => {
  const { dispatchTabStore, modalStore } = props;
  const { data } = dispatchTabStore;
  const cur = mFormat(`YYYY-MM-DD`);

  const getStatus = (dispatch: IDispatch) => {
    let status = "default";
    let text: string = "";

    // dispatchCd = a ? 운행
    if (dispatch.dispatchCd === "a") {
      if (
        cur >= dispatch.startDay &&
        (cur <= dispatch.endDay || isNil(dispatch.endDay))
      ) {
        status = "running";
        text = "현재 운행중";
      } else {
        text = "기사변경 예정";
      }
    }

    // dispatchCd = b ? 대차
    if (dispatch.dispatchCd === "b") {
      if (
        cur >= dispatch.startDay &&
        (cur <= dispatch.endDay || isNil(dispatch.endDay))
      ) {
        status = "running";
        text = "현재 임시대차 운행중";
      } else {
        text = "임시대차 예정";
      }
    }

    return { status, text };
  };

  return (
    <>
      {data?.length! > 0 ? (
        dispatchTabStore.data?.map((c: IDispatchItem, i: number) => {
          const { dr, dispatch, drBus } = c;
          const { status, text } = getStatus(dispatch);

          return (
            <DrDispatch
              key={i}
              status={
                <StCardTitleStatus $status={status}>{text}</StCardTitleStatus>
              }
              startDay={dispatch.startDay}
              endDay={dispatch.endDay}
              nm={dr.nm}
              phone={dr.phone}
              busNo={drBus.busNo}
              onClickHandler={() => {
                console.log(modalStore);
                modalStore?.setSelect(c.dispatch.dispatchId);
              }}
            />
          );
        })
      ) : (
        <EmptyList>
          해당 출근길에
          <br />
          기사님이 배차되지 않았습니다.
        </EmptyList>
      )}
    </>
  );
});

export const StCardTitleStatus = styled.div<{ $status: string }>`
  ${Subtitle3}
  color: ${(p) =>
    p.$status === "running"
      ? `${foundation.normal.negative.value}`
      : `${contents.primary.value}`};
`;

export const EmptyList = styled.div`
  background-color: ${contents.background.value};
  ${Body2}
  color: ${contents.accent.value};
  ${CenterFlex}
  padding: ${spacing[24].value} ${spacing[16].value};
  text-align: center;
  height: 100%;
  margin: auto;
`;
