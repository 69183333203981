import { contents, spacing } from "ms-ui";
import styled from "styled-components";

export const StLayout = styled.div`
  width: 100%;
  background-color: ${contents.background.value};
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
`;
