import { observer } from "mobx-react";
import { IApplyTab } from "../..";
import { NotiMsg } from "./NotiMsg";
import { Subtitle3, spacing } from "ms-ui";
import styled from "styled-components";
import { ApplyTabStore } from "../../../Store/ApplyTabStore";
import { isNil } from "ms_data";

/**
 * 상태 알림
 * @Todo 상태별 test
 */
export const NotiMsgTpl = observer((props: IApplyTab) => {
  const msg = getMsg(props.applyTabStore);

  return (
    <>
      {!isNil(msg) && (
        <div style={{ marginTop: spacing[8].value }}>
          <NotiMsg msg={getMsg(props.applyTabStore)} />
        </div>
      )}
    </>
  );
});

const getMsg = (applyTabStore: ApplyTabStore) => {
  const { boardStatus, rtStatus, data } = applyTabStore;

  if (rtStatus === "a") {
    return (
      <>
        <Subtitle>해당 출근길은 운행이 중단되었습니다.</Subtitle>
        모두의셔틀을 다시 이용하고 싶으시다면
        <br />맨 아래의 내 출근길 알림받기를 눌러주세요!
      </>
    );
  } else {
    switch (boardStatus.status) {
      case "board": {
        // 탑승중
        return <>현재 탑승중인 출근길입니다.</>;
      }

      case "before": {
        // 탑승예정
        return <>탑승 예정인 출근길입니다.</>;
      }

      case "complete": {
        // 탑승종료
        return <>탑승이 종료되었습니다.</>;
      }

      case "cancel": {
        // 탑승취소
        return <>탑승을 취소하셨습니다.</>;
      }

      case "wait": {
        // 탑승대기
        if (data?.rt?.rtCd === "b") {
          return <>탑승까지 대기자가 {data?.waitCnt}명 남았습니다.</>;
        } else {
          return;
        }
      }

      case "schd": {
        // 탑승확정
        return (
          <>
            운행일이 확정되면 모두의셔틀에서 안내드릴 예정입니다. <br /> 최대한
            빠르게 모셔드릴 수 있도록 하겠습니다.
          </>
        );
      }
    }
  }
};

export const Subtitle = styled.div`
  ${Subtitle3}
  margin-bottom: ${spacing[4].value};
`;
