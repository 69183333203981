import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Caption1,
  Body2,
  Subtitle1,
  foundation,
  borderRadius,
  Button,
} from "ms-ui";
import styled from "styled-components";

export interface IPaidCard {
  /**
   * 주문번호
   */
  orderNo: string;

  /**
   * 결제일시
   */
  createdAt: string;

  /**
   * 결제상태
   */
  status: string;

  /**
   * 결제금액
   */
  paidAmount: string;

  /**
   * 환불금액
   */
  refundAmount: string;
  visible: IVisible;
  onClick: IOnClick;
}

export interface IVisible {
  /**
   * 환불금액 노출여부
   */
  refundAmount: boolean;

  /**
   * payco 영수증 안내 노출여부
   */
  paycoCaption: boolean;

  /**
   * 영수증 버튼 노출여부
   */
  receipt: boolean;

  /**
   * 환불내역 버튼 노출여부
   */
  refundList: boolean;

  /**
   * 환불문의 버튼 노출여부
   */
  refundQuestion: boolean;
}

export interface IOnClick {
  /**
   * 영수증 버튼 click action
   */
  receipt: () => void;

  /**
   * 환불내역 버튼 click action
   */
  refundList: () => void;

  /**
   * 환불문의 버튼 click action
   */
  refundQuestion: () => void;
}

export const PaidCard = observer(
  ({
    orderNo,
    createdAt,
    status,
    paidAmount,
    refundAmount,
    visible,
    onClick,
  }: IPaidCard) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            <StDiv3>
              <StDiv4>
                <StDiv5>주문번호</StDiv5>
                <StDiv6>{orderNo}</StDiv6>
              </StDiv4>
            </StDiv3>
            <StDiv7>
              <StDiv8>
                <StDiv9>
                  <StDiv10>{createdAt}</StDiv10>
                  <StDiv11>{status}</StDiv11>
                </StDiv9>
              </StDiv8>
            </StDiv7>
            <StDiv12>
              <StDiv13>결제 금액</StDiv13>
              <StDiv14>
                <StDiv15>{paidAmount}</StDiv15>
                <StDiv16>원</StDiv16>
              </StDiv14>
            </StDiv12>
            {visible.refundAmount && (
              <StDiv17>
                <StDiv18>환불 금액</StDiv18>
                <StDiv19>
                  <StDiv20>{refundAmount}</StDiv20>
                  <StDiv21>원</StDiv21>
                </StDiv19>
              </StDiv17>
            )}
          </StDiv2>
          {visible.paycoCaption && (
            <StDiv22>
              <StDiv23>
                영수증은 payco web/app을 통해 확인해주시길 바랍니다.
              </StDiv23>
            </StDiv22>
          )}
          <StDiv24>
            {visible.receipt && (
              <Button
                color={`black`}
                size={`sm`}
                radius={`5`}
                outline={true}
                onClick={onClick.receipt}
                width={`100%`}
              >
                영수증
              </Button>
            )}
            {visible.refundList && (
              <Button
                color={`black`}
                size={`sm`}
                radius={`5`}
                outline={true}
                onClick={onClick.refundList}
                width={`100%`}
              >
                환불내역
              </Button>
            )}
            {visible.refundQuestion && (
              <Button
                color={`black`}
                size={`sm`}
                radius={`5`}
                outline={true}
                onClick={onClick.refundQuestion}
                width={`100%`}
              >
                환불문의
              </Button>
            )}
          </StDiv24>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[16].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[12].value};
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  height: 26px;
  flex-direction: column;
  gap: ${spacing[8].value};
  border-bottom: 1px solid ${contents.accent.value};
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Caption1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Caption1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv7 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const StDiv9 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv10 = styled.div`
  ${Caption1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv11 = styled.div`
  ${Caption1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv12 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv13 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  width: fit-content;
`;

const StDiv14 = styled.div`
  display: flex;
  width: fit-content;
`;

const StDiv15 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv16 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv17 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv18 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  width: fit-content;
`;

const StDiv19 = styled.div`
  display: flex;
  width: fit-content;
`;

const StDiv20 = styled.div`
  ${Subtitle1};
  color: ${foundation.normal.negative.value};
  text-align: right;
  width: fit-content;
`;

const StDiv21 = styled.div`
  ${Subtitle1};
  color: ${foundation.normal.negative.value};
  text-align: right;
  width: fit-content;
`;

const StDiv22 = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  border-radius: ${borderRadius[5].value};
  background: ${contents.tertiary.value};
  width: 100%;
`;

const StDiv23 = styled.div`
  ${Caption1};
  flex: 1 0 0;
  color: ${contents.accent.value};
  text-align: center;
`;

const StDiv24 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;
