import qs from "qs";
import { NavigateFunction } from "react-router-dom";
import { MSHUTTLE_PATH } from "src/constants/addr";
/**
 * string -> js
 * @param url url주소
 */
export function parseUrl<T>(url: string): T | any {
  return qs.parse(url, { ignoreQueryPrefix: true });
}

/**
 * js -> string
 * @param url url화 시킬 데이터
 */
export function stringifyUrl(url: any) {
  return qs.stringify(url, { addQueryPrefix: true });
}

export const goLogin = (redirect: string) => {
  window.location.href = `${MSHUTTLE_PATH.auth}${redirect}`;
};

export const goLoginReplace = (redirect: string) => {
  window.location.replace(`${MSHUTTLE_PATH.auth}${redirect}`);
};

export const goPhone = (redirect: string) => {
  window.location.href = `${MSHUTTLE_PATH.auth}/phone${redirect}`;
};

export const BackHistory = (navigate: NavigateFunction, exceptUrl: string) => {
  console.log("navigate.length: ", navigate.length);
  if (navigate.length <= 1 && exceptUrl) {
    navigate(exceptUrl, { replace: true });
  } else {
    navigate(-1);
  }
};

export const getCurrentUrl = (
  location: { pathname: string; hash: string; state: any; search: any },
  replacePathname?: string
) => {
  // ;
  // console.log(location);
  let rd = "";
  const { pathname, hash, state } = location;
  let { search } = location;

  const defaultUrl = MSHUTTLE_PATH.main;

  if (
    [
      "/auth",
      "/auth/social",
      "/auth/register",
      "/auth/findpw",
      "/auth/phone",
    ].indexOf(pathname) !== -1
  ) {
    const q = parseUrl(search);

    if (q.redirect !== null && q.redirect !== undefined) {
      rd = encodeURIComponent(q.redirect);
    } else {
      rd = encodeURIComponent(`${defaultUrl}/main`);
    }
  } else {
    if (state) {
      search = stringifyUrl({ ...parseUrl(search), ...state });
      // console.log(search);
    }

    // console.log(`${decodeURIComponent(decodeURI(search))}`);
    // console.log(pathname);
    // console.log(`${defaultUrl}${replacePathname || pathname}${decodeURI(search)}${hash}`);

    rd = encodeURIComponent(
      `${defaultUrl}${replacePathname || pathname}${decodeURI(search)}${hash}`
    );
    // console.log(rd);
  }

  // console.log(rd.length);
  return "?redirect=" + rd;
};
