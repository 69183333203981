import { observer } from "mobx-react";
import { RtInfo } from "./RtInfo";
import { spacing } from "ms-ui";
import { IApplyTab } from "../..";
import { ALink } from "ms_router";
import { MSHUTTLE_PATH } from "src/constants/addr";

/**
 * 경로 기본 정보
 * @todo 폐지된 경로일 때 onClick test
 */
export const RtInfoTpl = observer((props: IApplyTab) => {
  const { data, rtStatus } = props.applyTabStore;

  const rtInfo = {
    msg: data?.rt?.rtCd === "b" ? "운행중" : "운행예정",
    color:
      data?.rt?.rtCd === "b"
        ? ("secondary" as "secondary")
        : ("primary" as "primary"),
    rtId: data?.rt.rtId!,
    start: data?.rt.startInfo!,
    end: data?.rt.endInfo!,
    visible: rtStatus !== "a",
  };

  return (
    <div style={{ marginTop: spacing[8].value }}>
      {/* 운행 중단이 아닐 경우, 경로 상세페이지로 이동할 수 있다. */}
      {rtStatus !== "a" ? (
        <ALink
          style={{
            cursor: "pointer",
          }}
          to={`${MSHUTTLE_PATH.route}/${
            data?.rt?.rtCd === "b" ? "running" : "scheduled"
          }?rtId=${data?.rt?.rtId}`}
          data-id="applyDtl_rt"
        >
          <RtInfo {...rtInfo} />
        </ALink>
      ) : (
        <RtInfo {...rtInfo} />
      )}
    </div>
  );
});
