import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Body2, Subtitle3 } from "ms-ui";
import styled from "styled-components";

export interface IRefundItem {
  createdAt: string;
  title: string;
  amount: string;
}

export const RefundItem = observer(
  ({ createdAt, title, amount }: IRefundItem) => {
    return (
      <>
        <StDiv1>
          <StDiv2>{createdAt}</StDiv2>
          <StDiv3>
            <StDiv4>{title}</StDiv4>
            <StDiv5>
              <StDiv6>{amount}</StDiv6>
              <StDiv7>원</StDiv7>
            </StDiv5>
          </StDiv3>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[4].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  width: fit-content;
`;

const StDiv3 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv5 = styled.div`
  display: flex;
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Subtitle3};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Subtitle3};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;
