import { AxiosResponse } from "axios";
import { flow, makeObservable } from "mobx";
import { ServerStore } from "ms-ui";
import { IBus, IDispatch, IDr, IDrBus } from "ms_data";
import { get_dispatch } from "src/repository/Board/dispatch";

interface IDispatchStore {
  bus: IBus;
  busImg: any;
  dispatch: IDispatch;
  dr: IDr;
  drBus: IDrBus;
  drImg: any;
}

export class DispatchStore extends ServerStore<IDispatchStore> {
  constructor() {
    super();
    makeObservable(this, {
      loadData: flow,
    });
  }

  *loadData(dispatchId: string) {
    try {
      this.setLoading(true);
      const res: AxiosResponse = yield get_dispatch({ dispatchId }).req();

      this.setData(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }
}
