import { action, computed, makeObservable, observable } from "mobx";
import { PaidTabStore } from "./PaidTabStore";
import { DispatchTabStore } from "./DispatchTabStore";
import { ApplyTabStore } from "./ApplyTabStore";
import { ListStore } from "ms-ui";

export interface ITab {
  key: string;
  title: string;
}

export class TabStore extends ListStore<ITab> {
  applyTabStore: ApplyTabStore;
  dispatchTabStore: DispatchTabStore;
  paidTabStore: PaidTabStore;
  _tab?: string;

  constructor(props: {
    applyTabStore: ApplyTabStore;
    dispatchTabStore: DispatchTabStore;
    paidTabStore: PaidTabStore;
  }) {
    super();
    this.applyTabStore = props.applyTabStore;
    this.dispatchTabStore = props.dispatchTabStore;
    this.paidTabStore = props.paidTabStore;

    makeObservable(this, {
      _tab: observable,
      tab: computed,
      setTab: action,
    });
  }

  get tab() {
    return this._tab;
  }

  setTab = (tab: `apply` | `dispatch` | `paid`) => {
    this._tab = tab;
  };
}
