import { observer } from "mobx-react";
import { IApplyTab } from "../..";
import { BoardInfo } from "./BoardInfo";
import { TimeCnv, isNil } from "ms_data";

export const BoardInfoTpl = observer((props: IApplyTab) => {
  const { data, stLogic } = props.applyTabStore;

  const { boardingSt, isMonday, _data } = stLogic!;

  console.log(boardingSt);
  return (
    <>
      {data && (
        <BoardInfo
          nm={data?.user.nm}
          start={{
            alias: `${boardingSt.start?.idx! + 1}. ${boardingSt.start?.alias!}`,
            times:
              isMonday.start === true
                ? [
                    {
                      msg: "월",
                      time: TimeCnv.secondToString(
                        boardingSt.start?.timeScndMon || 0
                      ),
                    },
                    {
                      msg: "화수목금",
                      time: TimeCnv.secondToString(
                        boardingSt.start?.timeScnd || 0
                      ),
                    },
                  ]
                : [
                    {
                      msg: "월~금",
                      time: TimeCnv.secondToString(
                        boardingSt.start?.timeScnd || 0
                      ),
                    },
                  ],
          }}
          end={{
            alias: `${boardingSt.end?.idx! + 1}.  ${boardingSt.end?.alias!}`,
            times:
              isMonday.end === true
                ? [
                    {
                      msg: "월",
                      time: TimeCnv.secondToString(
                        boardingSt.end?.timeScndMon || 0
                      ),
                    },
                    {
                      msg: "화수목금",
                      time: TimeCnv.secondToString(
                        boardingSt.end?.timeScnd || 0
                      ),
                    },
                  ]
                : [
                    {
                      msg: "월~금",
                      time: TimeCnv.secondToString(
                        boardingSt.end?.timeScnd || 0
                      ),
                    },
                  ],
          }}
          period={`${_data.board?.startDay} ~ ${_data.board?.endDay}`}
          seat={data?.dispatch?.bus?.seat?.map((s: any, no: number) => {
            return Object.values(s).map((seat: any, i: number) => {
              if (seat.idx === stLogic?._data?.board?.seatId) {
                return (
                  <td key={i}>
                    {no}-{seat.seat_no}
                  </td>
                );
              }
            });
          })}
          question={data.question?.comment!}
          visible={{
            period:
              !isNil(_data.board?.startDay) && !isNil(_data.board?.endDay),
            seat:
              !isNil(stLogic?._data.board?.seatId) &&
              data?.dispatch?.bus &&
              stLogic?._data.board?.seatId !== -1,
            question: !isNil(data?.question),
          }}
          shuttleSeat={{
            seats: data?.dispatch?.bus?.seat,
            selectedIndex: stLogic?._data.board?.seatId!,
            isLoading: props.applyTabStore.isLoading,
            readOnly: true,
          }}
        />
      )}
    </>
  );
});
