import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Body2 } from "ms-ui";
import styled from "styled-components";

export interface IBusInfo {
  nm: string;
  no: string;
  img: React.ReactNode;
}

export const BusInfo = observer(({ nm, no, img }: IBusInfo) => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <table>
            <tbody>
              <StDiv3>
                <StDiv4>
                  <StDiv6>차량 정보</StDiv6>
                </StDiv4>
                <StDiv5>
                  <StDiv7>{nm}</StDiv7>
                </StDiv5>
              </StDiv3>
              <StDiv3>
                <StDiv4>
                  <StDiv8>차량 번호</StDiv8>
                </StDiv4>
                <StDiv5>
                  <StDiv9>{no}</StDiv9>
                </StDiv5>
              </StDiv3>
            </tbody>
          </table>
        </StDiv2>
        <StDiv10>{img}</StDiv10>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv3 = styled.tr`
  display: flex;
  width: 100%;
`;

const StDiv4 = styled.th`
  display: flex;
  width: 112px;
  padding: ${spacing[4].value} 0px;
  text-align: left;
`;

const StDiv5 = styled.td`
  display: flex;
  padding: ${spacing[4].value} 0px;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv6 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv7 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv9 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
