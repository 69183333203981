import { observer } from "mobx-react";
import { BusInfo } from "./BusInfo";
import { IDrInfo } from "../Form";
import { isNil } from "ms_data";
import { ms_file } from "src/constants/addr";
import styled from "styled-components";
import { Caption1, CenterFlex, contents } from "ms-ui";

export const BusInfoTpl = observer((props: IDrInfo) => {
  const { dispatchStore } = props;
  const { busImg, bus, drBus } = dispatchStore.data!;
  const width = window.innerWidth > 420 ? 420 : window.innerWidth;

  return (
    <BusInfo
      nm={bus.nm}
      no={drBus.busNo}
      img={
        !isNil(busImg) === true ? (
          <img
            src={`${ms_file}/${busImg?.img}`}
            width={`100%`}
            height={`100%r`}
          />
        ) : (
          <StBlankBox width={width - 32}>
            이미지가 없습니다.
            <br />
            빠른 시일내에 올려드릴게요!
          </StBlankBox>
        )
      }
    />
  );
});

export const StBlankBox = styled.div<{ width: number }>`
  ${CenterFlex}
  ${Caption1}
  width: ${(p) => p.width}px;
  height: calc(${(p) => p.width}px / 5 * 3);
  background-color: ${contents.background.value};
  border-radius: ${contents.tertiary.value};
  color: ${contents.description.value};
`;
