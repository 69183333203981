import React from "react";
import { observer } from "mobx-react";
import { RefundList } from "../RefundList";
import { List } from "./List";
import { IPaidTab } from "../..";
import { isNil } from "ms_data";
import { ModalStore, ModalAlert } from "ms-ui";

// 결제 내역
export const PaidList = observer((props: IPaidTab) => {
  const [modalStore] = React.useState(new ModalStore());

  return (
    <>
      {/* 결제 내역 */}
      <List {...props} modalStore={modalStore} />

      {/* 환불 내역 */}
      {!isNil(modalStore.select) ? (
        <RefundList modalStore={modalStore} />
      ) : null}

      {/* 환불문의 모달 */}
      <ModalAlert
        open={modalStore.open}
        onClick={() => modalStore.setOpen(false)}
        msg={
          <>
            환불을 원하시면
            <br />
            1:1문의로 요청해 주세요!
            <br />
            02{")"}775-1008
          </>
        }
        confirmMsg={`예`}
      />
    </>
  );
});
