import { computed, flow, makeObservable } from "mobx";
import { AxiosResponse } from "axios";
import { IApplyDetail } from "./Interface/IApplyTabStore";
import { BoardLogic, RtLogic, StLogic, isNil, mFormat } from "ms_data";
import { ServerStore } from "ms-ui";
import { get_pay_info_by_board_id } from "src/repository/Pay/info";

export class ApplyTabStore extends ServerStore<IApplyDetail> {
  constructor() {
    super();
    makeObservable(this, {
      hasBoardData: computed,
      boardLogic: computed,
      rtLogic: computed,
      stLogic: computed,
      rtStatus: computed,
      boardStatus: computed,
      loadData: flow.bound,
    });
  }

  *loadData(boardId: string) {
    this.setLoading(true);
    const res: AxiosResponse = yield get_pay_info_by_board_id({
      boardId,
    }).req();

    this.setData(res.data);
    this.setLoading(false);
  }

  get hasBoardData() {
    return !isNil(this.data?.board);
  }

  get boardLogic() {
    if (this.data !== undefined) {
      const { rt, board, serviceCd } = this.data;

      if (rt && board && serviceCd) {
        return new BoardLogic(rt, board, serviceCd[0]);
      } else {
        return;
      }
    } else {
      return;
    }
  }

  get rtLogic() {
    if (this.data !== undefined) {
      const { rt } = this.data;

      return new RtLogic(rt);
    } else {
      return;
    }
  }

  get rtStatus() {
    if (this.data !== undefined) {
      const { rt } = this.data;
      const rtLogic = this.rtLogic;

      return rt?.rtCd === "b"
        ? rtLogic?.runnStatus.key
        : rt?.rtCd === "c"
        ? rtLogic?.schdStatus.key
        : null;
    }
  }

  get stLogic() {
    if (this.data !== undefined) {
      const { board, stList, path } = this.data;
      return new StLogic({ board, stList, path });
    } else {
      return;
    }
  }

  // Noti
  get boardStatus() {
    const boardLogic: any = this.boardLogic;
    let status = "";
    let text = "";

    // 운행예정일 경우, 날짜 데이터가 없기 때문에 periodCd가 없다.
    if (boardLogic?.boardCd?.periodCd?.key === "b") {
      status = "board";
      text = "탑승중";
    } else if (boardLogic?.boardCd?.periodCd?.key === "c") {
      status = "before";
      text = "탑승예정";
    } else if (boardLogic?.boardCd?.periodCd?.key === "d") {
      status = "complete";
      text = "탑승종료";
    } else if (boardLogic?.boardCd?.boardCd?.key === "c") {
      status = "cancel";
      text = "탑승취소";
    } else if (boardLogic?.boardCd?.boardCd?.key === "e") {
      status = "wait";
      text = "탑승대기";
    } else if (boardLogic?.boardCd?.boardCd?.key === "b") {
      status = "schd";
      text = "탑승확정";
    }

    return { status, text };
  }

  /**
   * 배차정보 tab 활성화 조건
   * 운행중: 탑승중, 결제대기, 탑승중단+탑승종료전
   * 운행예정: 탑승확정
   */
  get activeDispatch(): boolean {
    let active: boolean = false;

    if (this.data) {
      const { rt, board } = this.data;
      if (rt?.rtCd === "b") {
        // 운행중
        if (["b", "d"].includes(board.boardCd) === true) {
          // 탑승중(b), 결제대기(d)
          active = true;
        } else if (board.boardCd === "f") {
          // 탑승중단(f)
          if (board?.endDay >= mFormat(`YYYY-MM-DD`)) {
            // 탑승 종료일이 지나지 않았을 경우
            active = true;
          }
        }
      } else if (rt?.rtCd === "c") {
        // 운행예정
        if (board.boardCd === "b") {
          // 탑승확정(b)
          active = true;
        }
      }
    }

    return active;
  }
}
