import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { Tabs } from "./Tabs";
import { ApplyTab } from "./ApplyTab";
import { DispatchTab } from "./DispatchTab";
import { PaidTab } from "./PaidTab";

import { TabStore } from "./Store/TabStore";
import { ApplyTabStore } from "./Store/ApplyTabStore";
import { DispatchTabStore } from "./Store/DispatchTabStore";
import { PaidTabStore } from "./Store/PaidTabStore";

import { BackHistory } from "src/func/url";
import { isNil } from "ms_data";
import { contents, MediaQuery, ModalAlert, ModalLoader, Nav } from "ms-ui";
import styled from "styled-components";
import { useURI } from "ms_router";
import { flowResult } from "mobx";

const ApplyDetail = observer(() => {
  const [applyTabStore] = useState(new ApplyTabStore());
  const [dispatchTabStore] = useState(new DispatchTabStore());
  const [paidTabStore] = useState(new PaidTabStore());
  const [tabStore] = useState(
    new TabStore({ applyTabStore, dispatchTabStore, paidTabStore })
  );

  const { navigate, getQuery, changeQuery, setQuery } = useURI();
  const q = getQuery<{
    boardId: string;
    tab: "apply" | "dispatch" | "paid";
    n?: string;
  }>();

  /** tab 정보가 유효한지 검사한다. */
  const isValidTab = (tab: string) => {
    const tabs = ["apply", "dispatch", "paid"];

    return tabs.includes(tab);
  };

  // tab정보가 유효하지 않을 경우, 기본값은 apply
  useEffect(() => {
    tabStore.setTab(isValidTab(q.tab) ? q.tab : `apply`);
  }, [q.tab]);

  // tab 변경시 스크롤 상단 고정
  useEffect(() => {
    window.scrollTo(0, 0);
    changeQuery({ tab: tabStore.tab }, true);
  }, [tabStore.tab]);

  useEffect(() => {
    // 기존의 n과 boareId 둘다 사용 가능하도록
    if (!isNil(q.n)) {
      setQuery({ boardId: q.n, tab: q.tab }, true);
    } else {
      // boardId 없으면 신청내역 리스트로 이동
      if (isNil(q.boardId)) {
        navigate(`/mypage/Apply`, { replace: true });
      } else {
        // tab정보가 없으면 기본 값인 apply로 지정해줌
        if (!isValidTab(q.tab)) changeQuery({ tab: "apply" }, true);
      }
    }
  }, [q.tab, q.n, q.boardId]);

  useEffect(() => {
    if (isNil(applyTabStore.data) && !isNil(q.boardId)) {
      (async () => {
        await flowResult(applyTabStore.loadData(q.boardId));
      })();
    }
  }, [q.boardId]);

  return (
    <>
      <ModalLoader active={applyTabStore.isLoading} />

      <StFixed>
        <Nav
          title={`탑승정보 상세`}
          left={{
            type: "back",
            onClick: () => BackHistory(navigate, "/main"),
          }}
        />

        {applyTabStore.hasBoardData === true ? (
          <Tabs tabStore={tabStore} applyTabStore={applyTabStore} />
        ) : undefined}
      </StFixed>

      {applyTabStore.hasBoardData === true ? (
        <>
          <StApplyDetail>
            {/* 신청, 탑승 정보 */}
            {tabStore.tab === "apply" ? (
              <ApplyTab applyTabStore={applyTabStore} />
            ) : null}

            {/* 배차, 기사 정보 */}
            {applyTabStore.activeDispatch === true &&
            tabStore.tab === "dispatch" ? (
              <DispatchTab
                dispatchTabStore={dispatchTabStore}
                tabStore={tabStore}
              />
            ) : null}

            {/* 결제 내역 */}
            {tabStore.tab === "paid" ? (
              <PaidTab paidTabStore={paidTabStore} />
            ) : null}
          </StApplyDetail>
        </>
      ) : !isNil(applyTabStore.data) ? (
        <ModalAlert
          open={true}
          msg={"회원님이 신청한 정보가 아닙니다."}
          onClick={() => navigate("/mypage/Apply", { replace: true })}
        />
      ) : undefined}
    </>
  );
});

export default ApplyDetail;

const StFixed = styled.div`
  position: fixed;
  width: 100%;
  ${MediaQuery}
  z-index: 10;
`;

const StApplyDetail = styled.div`
  overflow: auto;
  background-color: ${contents.background.value};
  padding-top: 100px;
  min-height: 100vh;
  height: calc(100dvh - 100px);
`;
