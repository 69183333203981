import React from "react";
import { observer } from "mobx-react";
import { StLayout } from "./Style";
import { ModalStore, spacing } from "ms-ui";
import { DispatchStore } from "../../Store/DispatchStore";
import { DrInfoTpl } from "../DrInfoTpl";
import { BusInfoTpl } from "../BusInfoTpl";

export interface IDrInfo {
  modalStore: ModalStore;
  dispatchStore: DispatchStore;
}

export const Form = observer((props: IDrInfo) => {
  return (
    <StLayout>
      <div style={{ marginTop: spacing[8].value }}>
        {/* 기사님 정보 */}
        <DrInfoTpl {...props} />
      </div>

      {/* 차량 정보 */}
      <BusInfoTpl {...props} />
    </StLayout>
  );
});
