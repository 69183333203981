import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Alert, Body2 } from "ms-ui";
import styled from "styled-components";

export interface IDrInfo {
  nm: string;
  phone: string;
  period: string;
  img: React.ReactNode;

  /**
   * 임시대차 노출여부
   */
  visible: boolean;

  /**
   * 임시대차 안내 문구
   */
  msg: React.ReactNode;
}

export const DrInfo = observer(
  ({ nm, phone, period, img, visible, msg }: IDrInfo) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            {visible && <Alert color={`primary`} msg={msg} />}
            <StDiv3>
              <table>
                <tbody>
                  <StDiv4>
                    <StDiv5>
                      <StDiv7>기사님 성함</StDiv7>
                    </StDiv5>
                    <StDiv6>
                      <StDiv8>{nm}</StDiv8>
                    </StDiv6>
                  </StDiv4>
                  <StDiv4>
                    <StDiv5>
                      <StDiv9>기사님 연락처</StDiv9>
                    </StDiv5>
                    <StDiv6>
                      <StDiv10>{phone}</StDiv10>
                    </StDiv6>
                  </StDiv4>
                  <StDiv4>
                    <StDiv5>
                      <StDiv11>운행기간</StDiv11>
                    </StDiv5>
                    <StDiv6>
                      <StDiv12>{period}</StDiv12>
                    </StDiv6>
                  </StDiv4>
                </tbody>
              </table>
            </StDiv3>
          </StDiv2>
          <StDiv13>{img}</StDiv13>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[12].value};
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv4 = styled.tr`
  display: flex;
  width: 100%;
`;

const StDiv5 = styled.th`
  display: flex;
  width: 112px;
  padding: ${spacing[4].value} 0px;
  text-align: left;
`;

const StDiv6 = styled.td`
  display: flex;
  padding: ${spacing[4].value} 0px;
  align-items: flex-end;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv7 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv8 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv10 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv11 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv12 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;

const StDiv13 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
