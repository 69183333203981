import { AxiosResponse } from "axios";
import { flow, makeObservable } from "mobx";
import { ServerStore } from "ms-ui";
import { IRefund } from "ms_data";
import { get_refund_list } from "src/repository/Pay/refund";

export class RefundStore extends ServerStore<IRefund[]> {
  constructor() {
    super();
    makeObservable(this, {
      loadData: flow,
    });
  }

  *loadData(orderNo: string) {
    try {
      this.setLoading(true);
      const res: AxiosResponse = yield get_refund_list({ orderNo }).req();
      const { refundList } = res.data;
      this.setData(refundList);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }
}
