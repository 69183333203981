import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Caption1 } from "ms-ui";
import styled from "styled-components";

export interface INotiMsg {
  msg: React.ReactNode;
}

export const NotiMsg = observer(({ msg }: INotiMsg) => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>{msg}</StDiv3>
        </StDiv2>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[12].value};
  justify-content: center;
  align-items: center;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const StDiv3 = styled.div`
  ${Caption1};
  color: ${contents.primary.value};
  width: fit-content;
`;
