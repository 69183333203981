import React from "react";
import { observer } from "mobx-react";
import { Paragraph, contents, Tag, Subtitle1, More } from "ms-ui";
import styled from "styled-components";

export interface IRtInfo {
  msg: string;
  rtId: string;
  start: string;
  end: string;
  visible: boolean;
  color: "primary" | "secondary";
}

export const RtInfo = observer(
  ({ msg, rtId, start, end, visible, color }: IRtInfo) => {
    return (
      <>
        <StDiv1 noMargin={true}>
          <StDiv2>
            {visible && (
              <Tag
                color={color}
                radius={`15`}
                light={false}
                msg={msg}
                width={`fit-content`}
              />
            )}
            <StDiv3>
              <StDiv4>S</StDiv4>
              <StDiv5>{rtId}</StDiv5>
              <StDiv6>ㅣ</StDiv6>
              <StDiv7>{start}</StDiv7>
              <StDiv8>&nbsp;-&nbsp;</StDiv8>
              <StDiv9>{end}</StDiv9>
            </StDiv3>
            {visible && <More />}
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled(Paragraph)`
  display: flex;
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  background: ${contents.secondary.value};
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Subtitle1};
  width: 18px;
  color: ${contents.tertiary.value};
`;

const StDiv7 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  width: fit-content;
`;
