import { observer } from "mobx-react";
import { ApplyTabStore } from "../Store/ApplyTabStore";
import { ModalStore, spacing } from "ms-ui";
import styled from "styled-components";
import { isNil } from "ms_data";
import { RtInfoTpl } from "./Comp/RtInfoTpl";
import { NotiMsgTpl } from "./Comp/NotiMsgTpl";
import { PayRequiesTpl } from "./Comp/PayRequestTpl";
import { BoardInfoTpl } from "./Comp/BoardInfoTpl";
import { ButtonsTpl } from "./Comp/ButtonsTpl";

export interface IApplyTab {
  applyTabStore: ApplyTabStore;
  modalStore?: ModalStore;
}

/**
 * 신청 정보
 */
export const ApplyTab = observer((props: IApplyTab) => {
  const { applyTabStore } = props;
  const { data, isLoading } = applyTabStore;

  return (
    <>
      {!isNil(data) === true && isLoading !== true ? (
        <StApplyTab>
          {/* 결제 요청 */}
          <PayRequiesTpl {...props} />

          {/* 경로 기본정보 */}
          <RtInfoTpl {...props} />

          {/* 상태 알림 */}
          <NotiMsgTpl {...props} />

          {/* 탑승정보 */}
          <BoardInfoTpl {...props} />

          {/* 버튼 */}
          <ButtonsTpl {...props} />
        </StApplyTab>
      ) : null}
    </>
  );
});

const StApplyTab = styled.div`
  & > :first-child {
    margin-top: ${spacing[4].value} !important;
  }
`;
