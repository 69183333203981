import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Button } from "ms-ui";
import styled from "styled-components";

export interface IButtonsDefault {
  /**
   * 수정하기
   */
  updateButton: IButtonProps;

  /**
   * 취소|중단하기
   */
  cancelButton: IButtonProps;
}

export interface IButtonProps {
  visible: boolean;
  onClick: () => void;
  children: string;
}

export const ButtonsDefault = observer(
  ({ updateButton, cancelButton }: IButtonsDefault) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            {updateButton.visible && (
              <Button
                color={"black"}
                size={"sm"}
                radius={"5"}
                outline={true}
                onClick={updateButton.onClick}
                width={"100%"}
              >
                {updateButton.children}
              </Button>
            )}
            {cancelButton.visible && (
              <Button
                color={"black"}
                size={"sm"}
                radius={"5"}
                outline={true}
                onClick={cancelButton.onClick}
                width={"100%"}
              >
                {cancelButton.children}
              </Button>
            )}
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;
