import { observer } from "mobx-react";
import { ApplyTabStore } from "./Store/ApplyTabStore";
import { ITab, TabStore } from "./Store/TabStore";
import { useEffect } from "react";
import { TabGroup } from "ms-ui";
import { useURI } from "ms_router";

export const Tabs = observer(
  (props: { applyTabStore: ApplyTabStore; tabStore: TabStore }) => {
    const { tabStore, applyTabStore } = props;
    const { getQuery } = useURI();
    const q = getQuery<{ tab: "apply" | "paid" | "dispatch" }>();

    // 배차정보 노출 조건에 맞도록 tab list 갱신
    useEffect(() => {
      if (applyTabStore.data) {
        const list = [
          { key: "apply", title: "신청정보" }, // apply
          { key: "paid", title: "결제내역" }, // paid
        ];

        if (
          applyTabStore.boardLogic?.periodCd?.key !== "d" &&
          applyTabStore.activeDispatch === true
        ) {
          list.splice(1, 0, { key: "dispatch", title: "배차정보" }); // dispatch
        }

        tabStore.initList(list);
      }
    }, [applyTabStore.data, applyTabStore.activeDispatch]);

    useEffect(() => {
      // querystring상 tab이 dispatch인데 tabList에 dispatch tab이 존재하지 않을 경우, 초기값인 apply tab으로 설정한다.
      if (
        q.tab === "dispatch" &&
        tabStore.list.find((tab) => tab.key === "dispatch") === undefined
      ) {
        tabStore.setTab("apply");
      } else {
      }
    }, [tabStore.list]);

    return (
      <>
        <TabGroup
          tabs={tabStore.list.map((tab: ITab) => ({
            key: tab.key,
            text: tab.title,
            onClick: () => {
              tabStore.setTab(tab.key as "apply" | "dispatch" | "paid");
            },
          }))}
          selectedKey={tabStore.tab!}
        />
      </>
    );
  }
);
