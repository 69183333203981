/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetDispatch  } from './interface/res/get/Res.Get.Dispatch';
import { ResGetInfoDispatch  } from './interface/res/get/Res.Get.InfoDispatch';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Dispatch {

get_dispatch(data: {   dispatchId?: string }){
 return GET<ResGetDispatch>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.board_resource}`,
   url: `/dispatch`,
 }, data);
}
get_dispatch_list_by_board_id(data: {   boardId: string }){
 return GET<ResGetInfoDispatch>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.board_resource}`,
   url: `/dispatch/list`,
 }, data);
}
 
}

export const DispatchRepository = new Dispatch();
export const { get_dispatch, get_dispatch_list_by_board_id } = DispatchRepository;
	