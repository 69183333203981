import { AxiosResponse } from "axios";
import { flow, makeObservable } from "mobx";
import { ServerStore } from "ms-ui";
import { IData, IPaidTabStore } from "./Interface/IPaidTabStore";
import { isNil } from "ms_data";
import { get_paid_list } from "src/repository/Pay/info";

export class PaidTabStore extends ServerStore<IPaidTabStore> {
  constructor() {
    super();
    makeObservable(this, {
      loadData: flow.bound,
    });
  }

  *loadData(boardId: string) {
    try {
      this.setLoading(true);
      const res: AxiosResponse<IData> = yield get_paid_list({
        boardId,
      }).req();
      this.matching(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }

  matching = (data: IData) => {
    const { impList, paidList, statusCdList } = data;
    const list: any[] = [];

    for (let i = 0; i < paidList.length; i++) {
      const paid = paidList[i].paid;

      if (!isNil(paid.impUid)) {
        if (impList.length > 0) {
          const imp = impList.find((_imp) => _imp.impUid === paid.impUid);
          list.push({
            paid: paidList[i].paid,
            imp,
            refundSumAmount: paidList[i].refundSumAmount,
          });
        }
      } else {
        list.push({
          paid: paidList[i].paid,
          refundSumAmount: paidList[i].refundSumAmount,
        });
      }
    }

    this.setData({ paidList: list, statusCdList });
  };
}
