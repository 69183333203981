import React from "react";
import { observer } from "mobx-react";
import {
  Paragraph,
  spacing,
  contents,
  Subtitle2,
  Body2,
  Tag,
  ShuttleSeat,
} from "ms-ui";
import styled from "styled-components";

export interface IBoardInfo {
  nm: string;
  start: IStInfo;
  end: IStInfo;
  period: string;
  seat: string;
  question: string;
  visible: IVisible;
  shuttleSeat: IShuttleSeat;
}

export interface IStInfo {
  alias: string;
  times: IStTimes[];
}

export interface IStTimes {
  msg: string;
  time: string;
}

export interface IVisible {
  period: boolean;
  seat: boolean;
  question: boolean;
}

export interface IShuttleSeat {
  seats: any;
  selectedIndex: number;
  isLoading: boolean;
  readOnly: boolean;
}

export const BoardInfo = observer(
  ({
    nm,
    start,
    end,
    period,
    seat,
    question,
    visible,
    shuttleSeat,
  }: IBoardInfo) => {
    return (
      <>
        <StDiv1 noMargin={false}>
          <StDiv2>
            <StDiv3>탑승 정보</StDiv3>
            <StDiv4>
              <StDiv5>
                <table>
                  <tbody>
                    <StDiv6>
                      <StDiv7>
                        <StDiv9>이름</StDiv9>
                      </StDiv7>
                      <StDiv8>
                        <StDiv10>{nm}</StDiv10>
                      </StDiv8>
                    </StDiv6>
                    <StDiv6>
                      <StDiv7>
                        <StDiv11>탑승지</StDiv11>
                      </StDiv7>
                      <StDiv8>
                        <StDiv12>
                          <StDiv13>{start.alias}</StDiv13>
                          <StDiv14>
                            {start.times.map((item, index) => {
                              return (
                                <div key={index}>
                                  <StDiv15>
                                    <Tag
                                      color={`primary`}
                                      radius={`5`}
                                      light={true}
                                      msg={item.msg}
                                      width={`fit-content`}
                                    />
                                    <StDiv16>{item.time}</StDiv16>
                                  </StDiv15>
                                </div>
                              );
                            })}
                          </StDiv14>
                        </StDiv12>
                      </StDiv8>
                    </StDiv6>
                    <StDiv6>
                      <StDiv7>
                        <StDiv17>하차지</StDiv17>
                      </StDiv7>
                      <StDiv8>
                        <StDiv18>
                          <StDiv19>{end.alias}</StDiv19>
                          <StDiv20>
                            {end.times.map((item, index) => {
                              return (
                                <div key={index}>
                                  <StDiv21>
                                    <Tag
                                      color={`primary`}
                                      radius={`5`}
                                      light={true}
                                      msg={item.msg}
                                      width={`fit-content`}
                                    />
                                    <StDiv22>{item.time}</StDiv22>
                                  </StDiv21>
                                </div>
                              );
                            })}
                          </StDiv20>
                        </StDiv18>
                      </StDiv8>
                    </StDiv6>
                    {visible.period && (
                      <StDiv6>
                        <StDiv7>
                          <StDiv23>탑승기간</StDiv23>
                        </StDiv7>
                        <StDiv8>
                          <StDiv24>{period}</StDiv24>
                        </StDiv8>
                      </StDiv6>
                    )}
                    {visible.seat && (
                      <StDiv6>
                        <StDiv7>
                          <StDiv25>탑승좌석</StDiv25>
                        </StDiv7>
                        <StDiv8>
                          <StDiv26>{seat}</StDiv26>
                        </StDiv8>
                      </StDiv6>
                    )}
                  </tbody>
                </table>
              </StDiv5>
              {visible.seat && (
                <StDiv27>
                  <ShuttleSeat {...shuttleSeat} />
                </StDiv27>
              )}
              {visible.question && (
                <StDiv28>
                  <table>
                    <tbody>
                      <StDiv29>
                        <StDiv30>
                          <StDiv32>기타문의</StDiv32>
                        </StDiv30>
                        <StDiv31>
                          <StDiv33>{question}</StDiv33>
                        </StDiv31>
                      </StDiv29>
                    </tbody>
                  </table>
                </StDiv28>
              )}
            </StDiv4>
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[24].value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[24].value};
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Subtitle2};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv4 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv5 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv6 = styled.tr`
  display: flex;
  width: 100%;
`;

const StDiv7 = styled.th`
  display: flex;
  width: 112px;
  padding: ${spacing[4].value} 0px;
  text-align: left;
`;

const StDiv8 = styled.td`
  display: flex;
  padding: ${spacing[4].value} 0px;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv10 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv11 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv12 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv13 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv14 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv15 = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const StDiv16 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv17 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv18 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv19 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv20 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv21 = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const StDiv22 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv23 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv24 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv25 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv26 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;

const StDiv27 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  width: 100%;
`;

const StDiv28 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv29 = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StDiv30 = styled.th`
  display: flex;
  width: 112px;
  padding: ${spacing[4].value} 0px;
  text-align: left;
`;

const StDiv31 = styled.td`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv32 = styled.div`
  ${Body2};
  width: 112px;
  flex-shrink: 0;
  color: ${contents.accent.value};
`;

const StDiv33 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.primary.value};
`;
