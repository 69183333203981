import { observer } from "mobx-react";
import { IApplyTab } from "../..";
import { ButtonsMake } from "./ButtonsMake";
import { ButtonsDefault } from "./ButtonsDefault";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { useState } from "react";
import { ModalAlert, ModalStore, spacing } from "ms-ui";

export const ButtonsTpl = observer((props: IApplyTab) => {
  const { data, boardLogic, rtStatus } = props.applyTabStore;
  const { isPossUpdate, isPossCancel, periodCd } = boardLogic!;
  const [alertModalStore] = useState(new ModalStore());

  // 탑승취소가 아니고, 업데이트가 가능한 상태 (수정)
  const isUpdate = data?.board.boardCd !== "c" && isPossUpdate === true;

  // 무료탑승이 아니고, 취소가 가능한 상태 (취소)
  const isCancel = data?.apply.applyCd !== "c" && isPossCancel === true;

  // 탑승취소가 아니고, 무료탑승이 아니고, 취소가 불가능한 상황인데 탑승중인 상태 (중단)
  const isPause =
    data?.board.boardCd !== "c" &&
    data?.apply.applyCd !== "c" &&
    (data?.board.boardCd === "b" ||
      (isPossCancel === false && periodCd?.key === "b"));

  // 운행중단 상태여서 알림받기 버튼이 노출되는 상태
  const isMake = rtStatus === "a";

  // 버튼이 하나라도 노출될 조건
  const isButton =
    (periodCd?.key !== "d" && (isUpdate || isCancel || isPause)) || isMake;

  // const isButton =
  //   // 수정버튼
  //   (data?.board.boardCd !== "c" && isPossUpdate === true) ||
  //   (data?.apply.applyCd !== "c" &&
  //     // 취소버튼
  //     (isPossCancel === true ||
  //       data?.board.boardCd === "b" ||
  //       // 중단버튼
  //       (isPossCancel === false && periodCd?.key === "b"))) ||
  //   // 출근길 알림받기 버튼
  //   rtStatus === "a";

  return (
    <>
      {isButton === true && (
        <div style={{ marginTop: spacing[8].value }}>
          {rtStatus === "a" ? (
            <ButtonsMake
              onClickHandler={() => {
                window.location.href = `${MSHUTTLE_PATH.make}`;
              }}
            />
          ) : (
            <>
              {data?.board.boardCd !== "c" && (
                <ButtonsDefault
                  updateButton={{
                    visible: boardLogic?.isPossUpdate === true,
                    onClick: () => {
                      window.location.href = `${MSHUTTLE_PATH.route}${
                        data?.rt.rtCd === "b" ? "/running" : "/scheduled"
                      }/update?boardId=${data?.board.boardId}`;
                    },
                    children: `수정하기`,
                  }}
                  cancelButton={{
                    visible: data?.apply.applyCd !== "c",
                    onClick: () => {
                      if (
                        boardLogic?.isPossCancel === true ||
                        (data?.board.boardCd === "b" &&
                          boardLogic?.periodCd?.key === "c")
                      ) {
                        if (data?.board.boardCd === "b") {
                          alertModalStore.setOpen(true);
                        } else {
                          window.location.href = `${MSHUTTLE_PATH.route}/cancel?boardId=${data?.board.boardId}`;
                        }
                      } else if (boardLogic?.periodCd?.key === "b") {
                        alertModalStore.setOpen(true);
                      }
                    },
                    children:
                      boardLogic?.isPossCancel === true ||
                      (data?.board.boardCd === "b" &&
                        boardLogic?.periodCd?.key === "c")
                        ? "취소하기"
                        : boardLogic?.periodCd?.key === "b"
                        ? data?.board.boardCd === "f"
                          ? "중단 취소하기"
                          : "중단하기"
                        : "",
                  }}
                />
              )}
            </>
          )}
        </div>
      )}

      {alertModalStore.open === true && (
        <ModalAlert
          open={alertModalStore.open}
          onClick={() => alertModalStore.setOpen(false)}
          msg={
            <>
              탑승
              {boardLogic?.isPossCancel === true ||
              (data?.board.boardCd === "b" && boardLogic?.periodCd.key === "c")
                ? "취소를"
                : data?.board.boardCd === "f"
                ? "중단 취소를"
                : "중단을"}{" "}
              원하시면
              <br />
              1:1문의로 요청해 주세요!
              <br />
              02{")"}775-1008
            </>
          }
        />
      )}
    </>
  );
});
