import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Button } from "ms-ui";
import styled from "styled-components";

export interface IButtonsMake {
  onClickHandler: () => void;
}

export const ButtonsMake = observer(({ onClickHandler }: IButtonsMake) => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <Button
            color={"black"}
            size={"sm"}
            radius={"5"}
            outline={true}
            onClick={onClickHandler}
            width={"100%"}
          >
            내 출근길 알림받기
          </Button>
        </StDiv2>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100%;
`;
