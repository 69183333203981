import { MediaQuery, zindex_modal } from "ms-ui";
import * as React from "react";
import styled from "styled-components";

interface Props {
  children: any;
  overflowY?: boolean;
}

export const ModalWrapper = ({ children, overflowY }: Props) => {
  /* 
     ios 15로 인해 모달이 열린 상태에서 수직으로 스크롤 시
     bottom address bar가 나오면서 div를 밀어내면서 뒤에 페이지가 잠시 노출되는 이슈와
     맵을 모달로 다룰 때에도 맵을 스크롤하고 싶은데 페이지가 스크롤되는 이슈가 있어서
     modal이 open(마운트)됐을 경우에 body를 고정시키도록 수정함
  */

  const [pageLoaded, setPageLoaded] = React.useState(false);

  React.useEffect(() => {
    let beforeScrollY = 0;
    if (typeof window !== "undefined") {
      beforeScrollY = window.scrollY || document.documentElement.scrollTop;
      document.querySelector("html")!.style.height = "100vh";
      document.querySelector("body")!.style.position = "fixed";
      document.querySelector("body")!.style.margin = "0 auth";
      document.querySelector("body")!.style.left = "0";
      document.querySelector("body")!.style.right = "0";
      setPageLoaded(true);

      return () => {
        document.querySelector("body")!.style.right = "";
        document.querySelector("body")!.style.left = "";
        document.querySelector("body")!.style.margin = "";
        document.querySelector("body")!.style.position = "";
        document.querySelector("html")!.style.height = "";
        window.scrollTo(0, beforeScrollY);
      };
    }
  }, []);

  return (
    <>
      {pageLoaded && (
        <ModalContainer overflowY={overflowY}>{children}</ModalContainer>
      )}
    </>
  );
};

export const UserLayout = `
        margin: 0 auto;
        width: 100%;
        // max-width: 420px;
        background-color: var(--gray-000);
        
        @media (max-width: 420px) {
            width: 100%;
        }
`;

const ModalContainer = styled.div<{ overflowY?: boolean }>`
  ${UserLayout}
  position: fixed;
  height: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: ${(p) => (p.overflowY === true ? "auto" : "hidden")};
  top: 0;
  z-index: ${zindex_modal};
  ${MediaQuery}
`;

export const iosBottom = {
  bottom: `
                bottom: constant(safe-area-inset-bottom);
                bottom: env(safe-area-inset-bottom);
        `,
  after: `
        
                ::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 100%;
                        width: 100%;
        
                        
                        padding-bottom: constant(safe-area-inset-bottom);
                        padding-bottom: env(safe-area-inset-bottom);
                        background-color: inherit;
                }
        

        `,
};
