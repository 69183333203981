import { observer } from "mobx-react";
import { IApplyTab } from "../..";
import { PayRequestDefault } from "./PayRequestDefault";
import { PayReqLogic } from "./PayReqLogic";
import { MSHUTTLE_PATH } from "src/constants/addr";

export const PayRequiesTpl = observer((props: IApplyTab) => {
  const { data } = props.applyTabStore;
  const { board, apply, pay, paid, payTry, rt } = data!;

  const { visible } = new PayReqLogic({
    pay,
    paid,
    apply,
    board,
    payTry,
    rt,
  });

  if (visible === true) {
    return (
      <PayRequestDefault
        day={
          // 일반 신규신청일 경우 ? -1일 16시
          data?.apply.applyCd === "a"
            ? data.cald.day
            : // 일반 신규탑승에 의한 연장복사일 경우 ? 당일 16시
            data?.apply.applyCd === "b"
            ? data.board.startDay
            : ""
        }
        dayVisible={
          // 결제기한은 운행중인 경로에 대해서만 존재함
          data?.rt?.rtCd === "b"
        }
        onClickHandler={() => {
          // @Todo data-id
          window.location.href = `${MSHUTTLE_PATH.pay}/?payId=${data?.pay.payId}`;
        }}
      />
    );
  } else {
    return;
  }
});
