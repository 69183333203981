import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Subtitle4, Body3, Caption1 } from "ms-ui";
import styled from "styled-components";

export interface IDrDispatch {
  status: React.ReactNode;
  startDay: string;
  endDay: string;
  nm: string;
  phone: string;
  busNo: string;
  onClickHandler: () => void;
}

export const DrDispatch = observer(
  ({
    status,
    startDay,
    endDay,
    nm,
    phone,
    busNo,
    onClickHandler,
  }: IDrDispatch) => {
    return (
      <>
        <StDiv1 onClick={onClickHandler}>
          <StDiv2>
            <StDiv3>{status}</StDiv3>
            <StDiv4>
              <StDiv5>{startDay}</StDiv5>
              <StDiv6>~</StDiv6>
              <StDiv7>{endDay}</StDiv7>
            </StDiv4>
          </StDiv2>
          <StDiv8>
            <StDiv9>
              <StDiv10>{nm}</StDiv10>
              <StDiv11>{phone}</StDiv11>
              <StDiv12>{busNo}</StDiv12>
            </StDiv9>
            <StDiv13>
              <StDiv14>자세히보기</StDiv14>
            </StDiv13>
          </StDiv8>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  background: ${contents.secondary.value};
  cursor: pointer;
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv4 = styled.div`
  display: flex;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Subtitle4};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Subtitle4};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Subtitle4};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv8 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv9 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv10 = styled.div`
  ${Body3};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv11 = styled.div`
  ${Body3};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv12 = styled.div`
  ${Body3};
  align-self: stretch;
  color: ${contents.primary.value};
`;

const StDiv13 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
`;

const StDiv14 = styled.div`
  ${Caption1};
  align-self: stretch;
  color: ${contents.description.value};
`;
