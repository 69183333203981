/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetPayInfo  } from './interface/res/get/Res.Get.PayInfo';
import { ResGetInfoBoard  } from './interface/res/get/Res.Get.InfoBoard';
import { ResGetInfoPaid  } from './interface/res/get/Res.Get.InfoPaid';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Info {

/**
 * 결제에 필요한 정보

*/
get_pay_info_by_pay_id(data: {   payId: string }){
 return GET<ResGetPayInfo>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/info`,
 }, data);
}
/**
 * 마이페이지에서 유저가 확인하기 위한 데이터

*/
get_pay_info_by_board_id(data: {   boardId: string }){
 return GET<ResGetInfoBoard>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/info/board`,
 }, data);
}
get_paid_list(data: {   boardId: string }){
 return GET<ResGetInfoPaid>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/info/paid`,
 }, data);
}
 
}

export const InfoRepository = new Info();
export const { get_pay_info_by_pay_id, get_pay_info_by_board_id, get_paid_list } = InfoRepository;
	